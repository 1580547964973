export const COUNTRY_ID = 244; // this might change once we populate production db
export const dummy = 'dummy';
export const localSkipStorageKey = 'hasOkoUserSkippedProfile';
export const CONFIRM_STATUS_EVENT_CHANGES = 'Confirm Event Status Change';
export const CONFIRM_EDIT_COPY = 'Confirm Changes';
export const ADD_PRODUCT = 'Add New Product';
export const ADD_NEW_PRODUCT_TITLE = 'Add new product';
export const MODIFY_PRODUCT_TITLE = 'Modify product';

export const transactionPickForSummary = ['id', 'purchase_date', 'supplier_name', 'buyer_name',
  'product_name', 'price_per_unit', 'lead_time', 'inco_abbv', 'payment_terms', 'eta', 'etd',
  'total', 'quantity', 'status', 'unit', 'company_name', 'port_origin', 'port_discharge',
  'net_weight', 'requested_delivery_date_to_dest', 'last_free_date', 'terminal_name',
  'port_origin_name', 'port_destination_name',
];

const buyerPickForSummary = ['buyer_hq_address', 'buyer_country_name', 'buyer_state_name',
  'buyer_city_name', 'buyer_zipcode'];

const supplierPickForSummary = ['supplier_hq_address', 'supplier_country_name', 'supplier_state_name',
  'supplier_city_name', 'supplier_zipcode'];

export const companyPickForSummary = (type) => (type === 'buyer' ? buyerPickForSummary : supplierPickForSummary);

export const documentsPicks = ['status', 'legacy_id', 'transaction_id', 'proforma_invoice', 'purchase_order',
  'isf', 'commercial_invoice', 'packing_list', 'technical_data_sheet', 'certificate_of_analysis', 'safety_data_sheet',
  'certificate_of_origin', 'shipping_insurance', 'dangerous_goods_declaration',
  'delivery_order', 'notice_of_arrival', 'form_7501', 'bill_of_lading'];

export const mainDocumentAvailable = {
  bill_of_lading: 'Bill of Lading',
  proof_of_delivery: 'Proof of Delivery',
};

export const chartStackedColors = [
  '#D7816A',
  '#93B5C6',
  '#DDEDAA',
  '#F0CF65',
  '#DDFBD2',
  '#A69658',
  '#D9B26F',
  '#716969',
  '#D65780',
  '#EE9480',
  '#F37748',
];

export const defaultColumnDashboard = [
  {
    column_label: 'Order',
    column_name: 'purchase_date',
    column_type: 'DATE',
    order: 1,
  },
  {
    column_label: 'PO#',
    column_name: 'legacy_id',
    column_type: 'TEXT',
    order: 2,
  },
  {
    column_label: 'supplier_buyer',
    column_name: 'company_name',
    column_type: 'TEXT',
    order: 3,
  },
  {
    column_label: 'Product',
    column_name: 'product_name',
    column_type: 'PRODUCT_NAME',
    order: 4,
  },
  {
    column_label: 'QTY',
    column_name: 'product_qty',
    column_type: 'PRODUCT_QTY',
    order: 5,
  },
  {
    column_label: 'Total',
    column_name: 'total',
    column_type: 'MONEY',
    order: 6,
  },
  {
    column_label: 'INCO',
    column_name: 'inco_abbv',
    column_type: 'TEXT',
    order: 7,
  },
  {
    column_label: 'ETA',
    column_name: 'eta',
    column_type: 'DATE',
    order: 8,
  },
  {
    column_label: 'Status',
    column_name: 'status',
    column_type: 'CHIP',
    order: 9,
  },
];

export const routeLineJsonTemplate = (routes) => ({
  type: 'geojson',
  data: {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'LineString',
      coordinates: routes,
    },
  },
});

export const templateShipJson = ({
  shipCoordinates,
  origin,
  destination,
  eta,
}) => ({
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: shipCoordinates,
      },
      properties: {
        id: 'shipEvents',
        column_label: 'Ship Events',
        description: 'Properties for ship popup',
        origin,
        destination,
        eta,
      },
    },
  ],
});

export const templateOriginDestination = ({ origin, destination }) => ({
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: origin,
      },
      properties: {
        id: 'origin',
        column_label: 'Container 1',
        description: 'Mumbai 1',
        pol: 'Port Houston - Texas, USA',
        postPod: 'Indira Dock - Mumbai, India',
        vessel: {
          name: 'MAERSK ENSHI',
          imo: 9502946,
          call_sign: '3FIL',
          mmsi: 355288000,
          flag: 'PA',
        },
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: destination,
      },
      properties: {
        id: 'destination',
        column_label: 'Container 1',
        description: 'Mumbai 1',
        pol: 'Port Houston - Texas, USA',
        postPod: 'Indira Dock - Mumbai, India',
        vessel: {
          name: 'MAERSK ENSHI',
          imo: 9502946,
          call_sign: '3FIL',
          mmsi: 355288000,
          flag: 'PA',
        },
      },
    },
  ],
});

export const shortMonths = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const timeSixMonths = 1000 * 60 * 60 * 24 * 30 * 6;
const today = new Date().getTime();
export const sixMonthsInMilliseconds = new Date(today - timeSixMonths).getTime();

export const allPages = 'ALL';
export const portPage = 'PORT_PERFORMANCE';
export const routePage = 'ROUTE_PERFORMANCE';

export const TOAST_CONFIG = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'colored',
  className: 'font-inter text-sm',
};
